import { invoiceFundingConstants } from '../constants';

const initialState = {
  contractor: null,
  loading: true,
  errorCode: null,
};

export const invoiceFunding = (state = initialState, action) => {
  switch (action.type) {
    case invoiceFundingConstants.GET_INVOICE_FUNDING_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        errorCode: null,
      };
    case invoiceFundingConstants.GET_INVOICE_FUNDING_FAILED:
      return {
        ...state,
        loading: false,
        errorCode: action.payload.errorCode,
      };
    case invoiceFundingConstants.SET_INVOICE_FUNDING_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
