import { bulkDocumentDownloadConstants, shareConstants } from '../constants';

const initialState = {
  list: [],
  listLoading: false,
  filterForm: {
    q: null,
    by_account_id: null,
    by_state: null,
    page: 1,
    per_page: shareConstants.DEFAULT_PER_PAGE,
    total_count: 0,
  },
  errorCode: null,
};

export const bulkDocumentDownloads = (state = initialState, action) => {
  switch (action.type) {
    case bulkDocumentDownloadConstants.GET_BULK_DOCUMENT_DOWNLOADS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        filterForm: { ...state.filterForm, total_count: action.payload.total_count },
        listLoading: false,
        errorCode: null,
      };
    case bulkDocumentDownloadConstants.GET_BULK_DOCUMENT_DOWNLOADS_FAILED:
      return {
        ...state,
        listLoading: false,
        filterForm: { ...state.filterForm, page: 1, total_count: 0 },
        errorCode: action.payload.errorCode,
      };
    case bulkDocumentDownloadConstants.SET_BULK_DOCUMENT_DOWNLOADS_LOADING:
      return {
        ...state,
        listLoading: action.payload,
      };
    case bulkDocumentDownloadConstants.SET_BULK_DOCUMENT_DOWNLOADS_FILTER:
      return {
        ...state,
        filterForm: action.payload,
      };
    default:
      return state;
  }
};
